import patch from '@/api/base/with-authorization/patch';
/**
 * Add images to venue or space
 *
 * @param {string} model venue or space
 * @param {number} id venue or space id
 * @param {object} data ids to remove
 *
 * @returns {Promise}
 */
export default function (model, id, data) {
    return patch(`image/${model}/${id}/bulk/`, data);
}
