import { defineComponent, computed } from '@vue/composition-api';
import { DAY_NAMES } from '@/shared/const';
export default defineComponent({
    props: {
        value: {
            type: Object,
            default: () => { }
        },
        label: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        day: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const dayLabel = computed(() => {
            switch (props.day) {
                case DAY_NAMES.MONDAY:
                    return root.$i18n.t('day_names.monday');
                case DAY_NAMES.TUESDAY:
                    return root.$i18n.t('day_names.tuesday');
                case DAY_NAMES.WEDNESDAY:
                    return root.$i18n.t('day_names.wednesday');
                case DAY_NAMES.THURSDAY:
                    return root.$i18n.t('day_names.thursday');
                case DAY_NAMES.FRIDAY:
                    return root.$i18n.t('day_names.friday');
                case DAY_NAMES.SATURDAY:
                    return root.$i18n.t('day_names.saturday');
                case DAY_NAMES.SUNDAY:
                    return root.$i18n.t('day_names.sunday');
                default:
                    return false;
            }
        });
        const dayData = computed(() => {
            return props.value ? props.value[props.day] || [] : [];
        });
        const onCheckClick = () => {
            emit('click', props.day, !!dayData.value.length);
        };
        return {
            close,
            dayData,
            dayLabel,
            open,
            onCheckClick
        };
    }
});
