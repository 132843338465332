var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-details"},[_c('h1',[_vm._v(_vm._s(_vm.$t('onboarding.venue.details.label_header')))]),_c('form',{staticClass:"venue-form",on:{"submit":function($event){$event.preventDefault();}}},[_c('InputHeader',{attrs:{"title":_vm.$t('onboarding.venue.details.input_label_venue_name'),"required":""}}),_c('WMInput',{attrs:{"id":"name","data-gtm":"venue_details_form_venue_name_input","max-length":100,"inline":true,"placeholder":_vm.$t(
            'onboarding.venue.details.input_placeholder_venue_name'
          ).toString(),"error":_vm.form.errors('name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('InputHeader',{attrs:{"title":_vm.$t('onboarding.venue.details.input_label_venue_about'),"is-title-html":"","required":""}}),_c('FormTextarea',{attrs:{"id":"description","data-gtm":"venue_details_form_venue_description_input","inline":true,"rows":8,"placeholder":_vm.$t(
            'onboarding.venue.details.input_placeholder_venue_about'
          ).toString(),"error":_vm.form.errors('description')},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('FormTextarea',{attrs:{"id":"venueRules","data-gtm":"venue_details_form_venue_rules_input","inline":true,"label":_vm.$t('onboarding.venue.details.input_label_venue_rules').toString(),"placeholder":_vm.$t(
            'onboarding.venue.details.input_placeholder_venue_rules'
          ).toString(),"error":_vm.form.errors('venueRules')},model:{value:(_vm.form.venueRules),callback:function ($$v) {_vm.$set(_vm.form, "venueRules", $$v)},expression:"form.venueRules"}}),_c('AddressAutocomplete',{attrs:{"id":"placeID","form":_vm.form,"data-gtm":"venue_details_form_venue_address_input","entity":"venue"},on:{"clear":function () { return (_vm.form.placeID = ''); },"setAddress":_vm.setupAddress}}),(_vm.form.placeID)?_c('WMInput',{attrs:{"data-gtm":"venue_details_form_venue_additional_address_input","inline":true,"placeholder":_vm.$t(
            'onboarding.venue.details.input_placeholder_venue_additional_information'
          ).toString()},model:{value:(_vm.form.additionalAddress),callback:function ($$v) {_vm.$set(_vm.form, "additionalAddress", $$v)},expression:"form.additionalAddress"}}):_vm._e(),_c('div',{staticClass:"checkbox-group form-input"},[_c('InputHeader',{attrs:{"title":_vm.$t('onboarding.venue.details.checkbox_group_label_features')}}),_vm._l((_vm.venueFeatures),function(val,key){return _c('FormCheckbox',{key:key,attrs:{"inline":true,"icon-classes":[("fa-solid fa-" + (val.icon_name) + " fa-xs")],"label":_vm.$t(
              ("onboarding.venue.details.checkbox_label_" + (val.feature_name))
            ).toString()},model:{value:(_vm.form.features[val.feature_id]),callback:function ($$v) {_vm.$set(_vm.form.features, val.feature_id, $$v)},expression:"form.features[val.feature_id]"}})})],2),_c('div',{staticClass:"photo-selector",attrs:{"id":"images"}},[_c('InputHeader',{attrs:{"title":_vm.$t('onboarding.venue.details.label_photo_selector'),"required":""}}),_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.venue.details.label_photo_selector_info'))+" ")]),_c('MultiplePhotoSelector',{attrs:{"entity-type":'VENUE',"on-loaded":_vm.onImagesLoaded,"is-should-reset":_vm.isImagesShouldReset,"error":_vm.form.errors('images'),"main-button-label":_vm.$t(
              'onboarding.venue.details.button_label_select_photos'
            ).toString()},model:{value:(_vm.form.images),callback:function ($$v) {_vm.$set(_vm.form, "images", $$v)},expression:"form.images"}})],1),_c('div',{staticClass:"checkbox-group form-input",attrs:{"id":"workingTime"}},[_c('InputHeader',{attrs:{"title":_vm.$t('onboarding.venue.details.checkbox_group_label_bookable_days'),"required":""}}),_vm._l((_vm.dayNames),function(dayName){return _c('FormTimeTableCheckbox',{key:dayName,attrs:{"inline":true,"day":dayName},on:{"click":_vm.setInitialTime},model:{value:(_vm.form.workingTime),callback:function ($$v) {_vm.$set(_vm.form, "workingTime", $$v)},expression:"form.workingTime"}})}),_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.form.errors('workingTime'))+" ")]),(Object.keys(_vm.filteredDays).length !== 0)?_c('div',{staticClass:"working-hours form-input"},[_c('InputHeader',{attrs:{"title":_vm.$t(
                'onboarding.venue.details.checkbox_group_label_bookable_hours.title'
              ),"required":""}}),_c('p',{staticClass:"working-hours-info"},[_vm._v(" "+_vm._s(_vm.$t('components.form_time_table_checkbox.errors.open_hours_less'))+" ")]),_c('Tabs',{attrs:{"tabs":[
              {
                title: _vm.$t(
                  'onboarding.venue.details.checkbox_group_label_bookable_hours.all_days_label'
                )
              },
              {
                title: _vm.$t(
                  'onboarding.venue.details.checkbox_group_label_bookable_hours.for_individual_days_label'
                )
              }
            ],"current-tab":_vm.currentNavigationCoTab},on:{"onTab":function($event){_vm.currentNavigationCoTab = $event}},scopedSlots:_vm._u([{key:"tab-content-0",fn:function(){return [_c('SelectorPopup',{attrs:{"open":_vm.workingHoursForAllDays.open,"close":_vm.workingHoursForAllDays.close},on:{"changeWorkingHours":_vm.handleChangeHoursForAllDays}})]},proxy:true},{key:"tab-content-1",fn:function(){return [_c('transition-group',{attrs:{"name":"list"}},_vm._l((_vm.filteredDays),function(data,day){return _c('SelectorPopup',{key:("key_" + day),staticClass:"working-time-all-days",attrs:{"open":data[0].open,"close":data[0].close,"day":day},on:{"changeWorkingHours":_vm.handleChangeWorkingHours}})}),1)]},proxy:true}],null,false,1525664236)})],1):_vm._e()],2),_c('div',{staticClass:"contact"},[_c('WMInput',{attrs:{"id":"contactName","data-gtm":"venue_details_form_contact_name_input","max-length":80,"required":true,"autofill":'false',"inline":true,"placeholder":_vm.$t(
              'onboarding.venue.details.input_placeholder_contact_name'
            ).toString(),"label":_vm.$t('onboarding.venue.details.input_label_contact_name').toString(),"error":_vm.form.errors('contactName')},model:{value:(_vm.form.contactName),callback:function ($$v) {_vm.$set(_vm.form, "contactName", $$v)},expression:"form.contactName"}}),_c('PhoneNumber',{attrs:{"id":"contactPhone","data-gtm":"venue_details_form_contact_phone_input","placeholder":_vm.$t(
              'onboarding.venue.details.input_placeholder_contact_phone'
            ).toString(),"inline":true,"error":_vm.form.errors('contactPhone'),"validate":_vm.isPhoneShouldValidating,"autofocused":false},on:{"validated":_vm.phoneValidated},model:{value:(_vm.form.contactPhone),callback:function ($$v) {_vm.$set(_vm.form, "contactPhone", $$v)},expression:"form.contactPhone"}}),_c('WMInput',{attrs:{"id":"contactEmail","data-gtm":"venue_details_form_contact_email_input","inline":true,"placeholder":_vm.$t(
              'onboarding.venue.details.input_placeholder_contact_email'
            ).toString(),"error":_vm.form.errors('contactEmail')},model:{value:(_vm.form.contactEmail),callback:function ($$v) {_vm.$set(_vm.form, "contactEmail", $$v)},expression:"form.contactEmail"}})],1),(_vm.form._generalError)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.form._generalError)+" ")]):_vm._e(),(!_vm.hasVenue)?_c('WMButton',{attrs:{"data-gtm":"venue_details_form_continue_button","icon-class-name":"fa-solid fa-arrow-right fa-lg","straight":"","disabled":!_vm.canContinue},on:{"click":_vm.mainAction}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$t( _vm.canContinue ? 'onboarding.venue.details.button_label_primary' : 'onboarding.venue.details.button_label_primary_disabled' ))+" ")])]):_vm._e()],1)]),_c('SaveFooter',{attrs:{"disabled":_vm.isSaving,"on-revert":_vm.onRevert,"on-save":_vm.onSaveChanges,"on-close":_vm.cancelInformingVenueUpdated,"is-changed":_vm.hasChanges,"is-saved":_vm.isChangesSaved,"page":'venue'}}),(_vm.isSettingsChanged)?_c('span'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }