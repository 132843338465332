import patch from '@/api/base/with-authorization/patch';
/**
 * Patch venue features.
 *
 * @param {object} data venue ID and removing feature IDs.
 *
 * @returns {Promise}
 */
export default function (data) {
    return patch('venue/feature/bulk/', data);
}
