import post from '@/api/base/with-authorization/post';
/**
 * Post venue features.
 *
 * @param {object} data venue ID and adding feature IDs.
 *
 * @returns {Promise}
 */
export default function (data) {
    return post('venue/feature/bulk/', data);
}
