import { defineComponent, ref, computed, watch, onMounted, set } from '@vue/composition-api';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import FormTextarea from '@/shared/components/form/FormTextarea.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import FormTimeTableCheckbox from '@/shared/components/form/time-table-checkbox/FormTimeTableCheckbox.vue';
import MultiplePhotoSelector from '@/shared/components/multiple-photo-selector/MultiplePhotoSelector.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import { debounce, transformWorkingTime, checkRemovedAndNewFeatures } from '@/util/utils';
import VenueDetailsForm from '@/pages/vendor/venue/details/forms/VenueDetailsForm';
import { DAY_NAMES } from '@/shared/const';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import saveVenue from '@/api/venue/patch';
import updateImageList from '@/api/image/patch';
import getFeatures from '@/api/venue/getFeatures';
import removeFeatures from '@/api/venue/patchFeatures';
import saveFeatures from '@/api/venue/postFeatures';
import toJSON from '@/api/base/toJSON';
import PhoneNumber from '@/uikit/PhoneNumber';
import EventBus from '@/shared/services/eventBus';
import AddressAutocomplete from '@/components/AddressAutocomplete.vue';
import InputHeader from '@/components/InputHeader.vue';
import Tabs from '@/components/Tabs.vue';
import SelectorPopup from '@/shared/components/form/time-table-checkbox/SelectorPopup.vue';
export default defineComponent({
    components: {
        AddressAutocomplete,
        FormCheckbox,
        FormTextarea,
        FormTimeTableCheckbox,
        InputHeader,
        MultiplePhotoSelector,
        PhoneNumber,
        SaveFooter,
        SelectorPopup,
        Tabs
    },
    props: {
        venue: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const form = ref(new VenueDetailsForm());
        const loadedForm = ref({});
        const venueId = ref(0);
        const isSaving = ref(false);
        const hasVenue = ref(false);
        const errors = ref([]);
        const isInformingVenueUpdated = ref(false);
        const isImagesShouldReset = ref(false);
        const isPhoneShouldValidating = ref(false);
        const venueDetails = computed(() => globalThis.$store.getters['$_vendor/venueDetails']);
        const venueFeatures = computed(() => globalThis.$store.getters['$_vendor/venueFeatures']);
        const canContinue = computed(() => {
            const keys = form.value.requiredFields();
            const filled = Object.keys(form.value).filter(key => {
                if (key === 'images')
                    return form.value.images.length < 3;
                if (key === 'contactPhone')
                    return !form.value.contactPhone.formatted_number;
                if (key === 'workingTime') {
                    return !Object.values(form.value.workingTime).some(day => day.length);
                }
                return (keys.includes(key) &&
                    (Array.isArray(form.value[key])
                        ? !form.value[key].length
                        : !form.value[key]));
            });
            return filled.length === 0;
        });
        const saveDataToLocalStorage = debounce(data => {
            globalThis.$store.commit('$_vendor/SET_VENUE_DETAILS', data);
        }, 500);
        const isSettingsChanged = computed(() => {
            if (!isEmpty(loadedForm.value)) {
                const isChanged = JSON.stringify(form.value) !== JSON.stringify(loadedForm.value);
                emit('settingsChanged', isChanged);
                if (isChanged && !form.value.id) {
                    saveDataToLocalStorage({
                        ...form.value,
                        completed: canContinue.value
                    });
                }
                return isChanged;
            }
            return false;
        });
        watch(() => venueFeatures.value, () => {
            if (venueFeatures.value) {
                venueFeatures.value.forEach(feature => {
                    set(form.value.features, feature.feature_id, false);
                });
                loadedForm.value = cloneDeep(form.value);
            }
        }, { immediate: true });
        const loadFeatures = async (venue) => {
            const selectedFeatures = await toJSON(getFeatures(venue.id));
            selectedFeatures.forEach(feature => {
                set(form.value.features, feature.feature_id, true);
            });
        };
        const loadVenue = async () => {
            const currentVenue = props.venue;
            if (currentVenue) {
                hasVenue.value = true;
                venueId.value = currentVenue.id;
                form.value.loadFromModel(currentVenue, /*except*/ ['features']);
                await loadFeatures(currentVenue);
            }
            else {
                form.value.loadFromModel(venueDetails.value);
            }
            delete form.value.isActive; // TODO: check this line
        };
        const setupAddress = (val) => {
            form.value._errors.placeID = '';
            form.value.setupAddress(val.id, val.address);
        };
        const startNewVenue = () => {
            form.value = new VenueDetailsForm();
            loadedForm.value = cloneDeep(form.value);
        };
        const restoreVenue = () => {
            form.value.loadFromModel(venueDetails.value);
        };
        const hasChanges = () => {
            return hasVenue.value && isSettingsChanged.value;
        };
        const isChangesSaved = () => {
            return !hasChanges() && isInformingVenueUpdated.value;
        };
        const startInformingVenueUpdated = () => {
            isInformingVenueUpdated.value = true;
        };
        const cancelInformingVenueUpdated = () => {
            isInformingVenueUpdated.value = false;
        };
        const onImagesLoaded = () => {
            isImagesShouldReset.value = false;
        };
        const onRevert = () => {
            form.value = cloneDeep(loadedForm.value);
            isImagesShouldReset.value = true;
        };
        const checkFormValid = (withAdditionalError = null) => {
            errors.value = [];
            form.value.validate();
            Object.keys(form.value).forEach(key => {
                const err = form.value.errors(key);
                if (err) {
                    errors.value.push(key);
                }
            });
            if (withAdditionalError && !errors.value.includes(withAdditionalError)) {
                errors.value.push(withAdditionalError);
            }
            if (errors.value.length === 0) {
                return true;
            }
            root.$scrollTo(`#${errors.value[0]}`, 1500, { offset: -100 });
            return false;
        };
        const updateFeatures = async () => {
            const { removedIds, newIds } = checkRemovedAndNewFeatures(loadedForm.value.features, form.value.features);
            if (removedIds.length) {
                await removeFeatures({
                    body: JSON.stringify({
                        venue_id: venueId.value,
                        feature_ids_to_delete: removedIds
                    })
                });
            }
            if (newIds.length) {
                await saveFeatures({
                    body: JSON.stringify({
                        venue_id: venueId.value,
                        feature_ids: newIds
                    })
                });
            }
        };
        const updateImages = async () => {
            const oldIds = loadedForm.value.images.map(x => x.image_id || x);
            const newIds = form.value.images.map(x => x.image_id || x);
            const removedIds = oldIds.filter(x => !newIds.includes(x));
            await updateImageList('venue', venueId.value, {
                body: JSON.stringify({
                    images_to_delete_ids: removedIds,
                    images_ids: newIds
                })
            });
        };
        const saveChanges = async () => {
            const _venue = form.value.buildSendData();
            _venue.working_time = transformWorkingTime(_venue.working_time);
            if (!_venue.place_id) {
                delete _venue.place_id;
            }
            await updateFeatures();
            await updateImages();
            await saveVenue(venueId.value, {
                body: JSON.stringify(_venue)
            });
            loadedForm.value = cloneDeep(form.value);
        };
        const onSaveChanges = () => {
            cancelInformingVenueUpdated();
            if (!isEqual(form.value.contactPhone, loadedForm.value.contactPhone)) {
                // Phone number was changed => need to validate it
                isPhoneShouldValidating.value = true;
                return;
            }
            if (checkFormValid()) {
                saveChanges()
                    .then(startInformingVenueUpdated)
                    .catch(cancelInformingVenueUpdated);
            }
        };
        const mainAction = () => {
            isPhoneShouldValidating.value = true;
        };
        const continueCreation = async () => {
            globalThis.$store.commit('$_vendor/SET_VENUE_DETAILS', {
                ...form.value,
                completed: true
            });
            EventBus.$emit('updateWizardStep');
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.FACILITIES
            });
        };
        const phoneValidated = async (isPhoneValid) => {
            isPhoneShouldValidating.value = false;
            const withContactPhoneError = isPhoneValid ? null : 'contactPhone';
            if (checkFormValid(withContactPhoneError)) {
                if (hasVenue.value) {
                    saveChanges()
                        .then(startInformingVenueUpdated)
                        .catch(cancelInformingVenueUpdated);
                }
                else {
                    await continueCreation();
                }
            }
        };
        EventBus.$on('startNewVenue', () => {
            startNewVenue();
        });
        EventBus.$on('restoreVenue', () => {
            restoreVenue();
        });
        onMounted(() => {
            loadVenue().then(() => {
                loadedForm.value = cloneDeep(form.value);
                loadedForm.value.images = loadedForm.value.images.map(el => el.image_id);
            });
            currentNavigationCoTab.value = areWorkingHoursSame.value ? 0 : 1;
        });
        const dayNames = Object.values(DAY_NAMES);
        const currentNavigationCoTab = ref(0);
        const filteredDays = computed(() => {
            const filteredObj = Object.entries(form.value.workingTime).filter(arr => arr[1].length);
            return Object.fromEntries(filteredObj);
        });
        const allWorkingHours = computed(() => Object.values(filteredDays.value));
        const areWorkingHoursSame = computed(() => {
            return allWorkingHours.value.every((el, i, arr) => JSON.stringify(el) === JSON.stringify(arr[arr.length - 1]));
        });
        const workingHoursForAllDays = computed(() => {
            // const allWorkingHours = Object.values(filteredDays.value);
            return {
                open: areWorkingHoursSame.value
                    ? allWorkingHours.value[0][0].open
                    : '— : —',
                close: areWorkingHoursSame.value
                    ? allWorkingHours.value[0][0].close
                    : '— : —'
            };
        });
        const handleChangeWorkingHours = (day, hours) => {
            form.value.workingTime[day] = [hours];
        };
        const handleChangeHoursForAllDays = (_, hours) => {
            for (const key in form.value.workingTime) {
                if (form.value.workingTime[key].length) {
                    form.value.workingTime[key] = [hours];
                }
            }
        };
        const setInitialTime = (dayName, status) => {
            if (status) {
                form.value.workingTime[dayName] = [];
                return;
            }
            const time = Object.values(form.value.workingTime).find(val => val.length);
            if (loadedForm.value.workingTime[dayName].length) {
                form.value.workingTime[dayName] = loadedForm.value.workingTime[dayName];
            }
            else if (time) {
                form.value.workingTime[dayName] = time;
            }
            else {
                form.value.workingTime[dayName] = [
                    {
                        open: '09:00',
                        close: '18:00'
                    }
                ];
            }
        };
        return {
            canContinue,
            currentNavigationCoTab,
            dayNames,
            errors,
            filteredDays,
            form,
            hasVenue,
            isImagesShouldReset,
            isInformingVenueUpdated,
            isPhoneShouldValidating,
            isSaving,
            isSettingsChanged,
            loadedForm,
            venueDetails,
            venueFeatures,
            venueId,
            workingHoursForAllDays,
            DAY_NAMES,
            cancelInformingVenueUpdated,
            handleChangeWorkingHours,
            handleChangeHoursForAllDays,
            hasChanges,
            isChangesSaved,
            mainAction,
            onImagesLoaded,
            onRevert,
            onSaveChanges,
            phoneValidated,
            restoreVenue,
            saveChanges,
            setupAddress,
            startInformingVenueUpdated,
            startNewVenue,
            setInitialTime
        };
    }
});
