import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 3
        }
    },
    setup(_, { emit }) {
        const onChange = (event) => {
            emit('input', event.target.value);
        };
        return {
            onChange
        };
    }
});
