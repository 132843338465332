import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import DropdownList from '@/shared/components/form/DropdownList.vue';
import { dateFormatter, now } from '@/util/dates';
import vClickOutside from 'v-click-outside';
import { DAY_NAMES } from '@/shared/const';
export default defineComponent({
    components: {
        DropdownList
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        day: {
            type: String,
            default: ''
        },
        open: {
            type: String,
            default: ''
        },
        close: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit, root }) {
        const generate24HourGrid = () => {
            const hours = [];
            const dayPointer = now.startOf('day');
            hours.push({
                value: dayPointer.format('HH:mm'),
                label: dayPointer.format('HH:mm')
            });
            for (let i = 0; i < 48; i += 1) {
                let last = null;
                if (dayPointer.format('HH:mm') === '23:30')
                    last = '24:00';
                dayPointer.add(30, 'm');
                hours.push({
                    value: last || dayPointer.format('HH:mm'),
                    label: last || dayPointer.format('HH:mm')
                });
            }
            return hours;
        };
        const closingTime = ref('');
        const openingTime = ref('');
        const hoursList = ref(generate24HourGrid());
        const closeHoursVisible = ref(false);
        const openHoursListVisible = ref(false);
        const openHoursErrorVisible = ref(false);
        const openingLabelIndex = computed(() => {
            return hoursList.value.findIndex(x => x.value === openingTime.value);
        });
        const closingLabelIndex = computed(() => {
            return hoursList.value.findIndex(x => x.value === closingTime.value);
        });
        const labelsFrom = computed(() => {
            const index = closingLabelIndex.value;
            return hoursList.value.slice(0, index - 7);
        });
        const hoursListNextIndex = (index) => {
            return index < hoursList.value.length - 1 ? index + 1 : index;
        };
        const defaultLabelFrom = computed(() => {
            const index = openingLabelIndex.value;
            return hoursList.value[hoursListNextIndex(index)].value;
        });
        const labelsTo = computed(() => {
            const index = openingLabelIndex.value;
            return hoursList.value.slice(index + 8);
        });
        const defaultLabelTo = computed(() => {
            const index = closingLabelIndex.value;
            return hoursList.value[hoursListNextIndex(index)].value;
        });
        const save = () => {
            openHoursErrorVisible.value = false;
            const calculatedOpeningTime = dateFormatter(openingTime.value, 'HH:mm');
            const calculatedClosingTime = dateFormatter(closingTime.value, 'HH:mm');
            if (calculatedClosingTime <= calculatedOpeningTime) {
                calculatedClosingTime.add(1, 'd');
            }
            calculatedOpeningTime.add(4, 'h');
            if (calculatedOpeningTime > calculatedClosingTime) {
                openHoursErrorVisible.value = true;
                return;
            }
            emit('changeWorkingHours', props.day, {
                open: openingTime.value === '— : —' ? '00:00' : openingTime.value,
                close: closingTime.value === '— : —' ? '24:00' : closingTime.value
            });
        };
        const toggleOpenHoursList = () => {
            openHoursListVisible.value = !openHoursListVisible.value;
        };
        const toggleCloseHoursList = () => {
            closeHoursVisible.value = !closeHoursVisible.value;
        };
        const onOpenHoursInput = (value) => {
            openingTime.value = value;
            openHoursListVisible.value = false;
            save();
        };
        const onCloseHoursInput = (value) => {
            closingTime.value = value;
            closeHoursVisible.value = false;
            save();
        };
        watch(() => [props.open, props.close], () => {
            openingTime.value = props.open;
            closingTime.value = props.close;
        }, { immediate: true });
        const dayLabel = computed(() => {
            switch (props.day) {
                case DAY_NAMES.MONDAY:
                    return root.$i18n.t('day_names.monday');
                case DAY_NAMES.TUESDAY:
                    return root.$i18n.t('day_names.tuesday');
                case DAY_NAMES.WEDNESDAY:
                    return root.$i18n.t('day_names.wednesday');
                case DAY_NAMES.THURSDAY:
                    return root.$i18n.t('day_names.thursday');
                case DAY_NAMES.FRIDAY:
                    return root.$i18n.t('day_names.friday');
                case DAY_NAMES.SATURDAY:
                    return root.$i18n.t('day_names.saturday');
                case DAY_NAMES.SUNDAY:
                    return root.$i18n.t('day_names.sunday');
                default:
                    return false;
            }
        });
        return {
            closingTime,
            closeHoursVisible,
            dayLabel,
            defaultLabelFrom,
            defaultLabelTo,
            hoursList,
            labelsFrom,
            labelsTo,
            openingTime,
            openHoursErrorVisible,
            openHoursListVisible,
            onCloseHoursInput,
            onOpenHoursInput,
            save,
            toggleCloseHoursList,
            toggleOpenHoursList
        };
    }
});
