import get from '@/api/base/with-authorization/get';
/**
 * Fetch a list of venue features.
 *
 * @param {number} venueID Venue ID.
 *
 * @returns {Promise}
 */
export default function (venueID) {
    return get(`venue/${venueID}/feature/`);
}
