import { defineComponent, onMounted, watch, ref } from '@vue/composition-api';
export default defineComponent({
    name: 'Tabs',
    props: {
        tabs: {
            type: Array,
            required: true
        },
        currentTab: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        onMounted(() => {
            updateRailTabStyle();
        });
        const tabTransitionName = ref('tab-slot-left');
        const railTabStyle = ref({});
        const itemRefs = ref([]);
        const updateRailTabStyle = () => {
            const railTab = itemRefs.value[props.currentTab].getElementsByClassName('tabs-title')[0];
            railTabStyle.value = {
                width: railTab.offsetWidth + 'px',
                left: railTab.offsetLeft + 'px'
            };
        };
        watch(() => props.currentTab, (to, from) => {
            tabTransitionName.value =
                to < from ? 'tab-slot-right' : 'tab-slot-left';
            updateRailTabStyle();
        });
        return {
            itemRefs,
            tabTransitionName,
            railTabStyle,
            updateRailTabStyle
        };
    }
});
